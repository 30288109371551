<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <div>
        <b>{{$t('label.itinerary')}}</b>
      </div>
      <div class="position-icon">
        <img size="sm" width="30px" height="30px" 
          src="/img/iconos/GIF_AYUDA.gif"
          @click="ModalHelp=true"
        >
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <loading-overlay
          :active="loadingOverlay"
          :is-full-page="true"
          loader="bars"
        />
        <!--CCol sm="12">
          <div class="row justify-content-center mb-2">
            <div class="text-center">
              <h3>{{ today }}</h3>
            </div>
          </div>
        </CCol-->

        <CCol sm="12">
          <div class="row justify-content-between">
            <div class="col-sm-12 col-lg-10 tex-left">
              <div class="row text-left">
                <div class="col-auto text-left">
                  <CSelect
                    :options="months"
                    :value="Month"
                    @change="selectMonth($event)"
                  />
                </div>
                <div class="col-auto text-left">
                  <CSelect
                    :options="computedYears"
                    :value="year"
                    @change="selectYear($event)"
                  />
                </div>
                <div class="col-auto text-left">
                  <p style="margin-top: 0.5rem">{{$t('label.service')}} :</p>
                </div>
                <div class="col-3 text-left">
                  <v-select
                    :placeholder="$t('label.select')"
                    :options="computedServiceList"
                    class="select-services--vesselIndex"
                    v-model="ServiceModel"
                    @input="selectService"
                  />
                </div>
                <div class="col-auto text-left">
                  <p style="margin-top: 0.5rem">{{$t('label.vessel')}} :</p>
                </div>
                <div class="col-3 text-left">
                  <v-select
                    :placeholder="$t('label.select')"
                    :options="computedVesselList"
                    class="select-services--vesselIndex"
                    v-model="VesselModel"
                    @input="selectVessel"
                  >
                    <template slot="option" slot-scope="option">
                      <div class="row justify-content-between">
                        <div class="col">
                          {{ option.label }}
                        </div>
                        <div class="col text-right">
                          <CImg
                            :src="option.icon"
                            width="40"
                            height="25"
                            class="ml-5"
                          />
                        </div>
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="col-auto" >
                  <CButton
                    size="sm"
                    color="edit"
                    class="d-flex align-items-center"
                    v-c-tooltip="{
                      content: $t('label.reset')+$t('label.table'),
                      placement: 'top',
                    }"
                    @click="refresCalendar"
                  >
                    <CIcon name="cil-loop-circular" />
                  </CButton>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-2 text-right">
                <CButton
                  square
                  color="add"
                  @click="toggleShowModalCrearItinerario"
                  v-c-tooltip="{
                    content: $t('label.itinerary'),
                    placement:'top-end'
                  }"
                >
                  <CIcon name="cil-playlist-add" /><span class="ml-1"
                    >{{$t('label.nuevo')}}</span
                  >
                </CButton>
            </div>
          </div>
        </CCol>
        <CCol sm="12" class="halto">
          <full-calendar
            :config.sync="configCallendar"
            :events="computedEvents"
            :header="header"
            @event-drop="eventDrop($event)"
            :drop="drop"
            @event-selected="eventSelectedCalendar($event)"
            class="calendar-itinerary"
            :key="callendarKey"
            ref="calendar"
            id="calendar"
          />
        </CCol>

        <modalItinerario
          :modal.sync="showModal"
          @updated-modal="updateCLoseModal"
          :title="titleModal"
          :editModal="editModal"
          @refresh-data-calendar="refresCalendar"
          :itinerarySelected="itinerarySelected"
        />
        <ModalHelpItinerary
          :modal.sync="ModalHelp"
          @Close="ModalHelp=false"
        />
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import { FullCalendar } from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.css';
import modalItinerario from './modal-itinerario';
import ModalHelpItinerary from './modal-help-itinerary';
import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';

const meses = [
  { label: 'ENERO', value: 1 },
  { label: 'FEBRERO', value: 2 },
  { label: 'MARZO', value: 3 },
  { label: 'ABRIL', value: 4 },
  { label: 'MAYO', value: 5 },
  { label: 'JUNIO', value: 6 },
  { label: 'JULIO', value: 7 },
  { label: 'AGOSTO', value: 8 },
  { label: 'SEPTIEMBRE', value: 9 },
  { label: 'OBTUBRE', value: 10 },
  { label: 'NOVIEMBRE', value: 11 },
  { label: 'DICIEMBRE', value: 12 },
];
//DATA
function data() {
  return {
    ModalHelp: false,
    events: [],
    config: {
      locale: this.$i18n.locale,
      defaultView: 'month',
      editable:false,
      //events: this.events,
    },
    header: {
      left: "today",
      center: "title",
      right: "listYear,month,agendaWeek"
    },
    showModal: false,
    editModal: false,
    titleModal: '',
    CompanyBranchId: '',
    year: '',
    VesselId: '',
    ServiceId: '',
    Month: '',
    itemsBuque: [],
    VesselModel: '',
//    months: meses,
    itemsServices: [],
//    today: '',
    filterActive: false,
    yearsSelected: [],
    loadingOverlay: false,
    itinerarySelected: {},
    drop: false,
    OrigitPortId: '',
    ServiceModel: '',
    callendarKey: 0,
    periodo: new Date().getFullYear(),
  };
}

function toggleShowModalCrearItinerario() {
  this.showModal = this.ServiceModel;
  this.titleModal = this.$t('label.nuevo')+this.$t('label.itinerary');
}

function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.itinerarySelected = {};
}

async function refresCalendar() {
  await this.mountedMont();
  this.VesselModel = '';
  this.VesselId = '';
  this.ServiceId = '';
  this.filterActive = false;
  this.drop = false;
  this.itinerarySelected = {};
}

async function eventDrop(event) {
  const arrayIntinerary = await this.events.filter(
    (element) => element.ItineraryId === event.id
  );

  await this.checkServicePortList(arrayIntinerary[0].ServiceId);

  const ServiceItineraryJson = {
    ItineraryId: arrayIntinerary[0].ItineraryId,
    ServiceVesselId: arrayIntinerary[0].ServiceVesselId,
    ServicePortOriginId: this.OrigitPortId,
    ServicePortDestinationId: arrayIntinerary[0].ServicePortDestinationId,
    // Eta: `${DateFormater.toNormalDate(event.start._d, '-')} ${this.getHourDate(
    //   event.start._d
    // )}`,
    // Etd: `${DateFormater.toNormalDate(event.end._d, '-')} ${this.getHourDate(
    //   event.end._d
    // )}`,
    Eta: `${event.start._i[0]}-${event.start._i[1] + 1}-${
      event.start._i[2]
    } ${this.getHourDate(event.start._d)}`,
    Etd: `${event.end._i[0]}-${event.end._i[1] + 1}-${
      event.end._i[2]
    } ${this.getHourDate(event.end._d)}`,
    Voyage: arrayIntinerary[0].Voyage,
    Color: arrayIntinerary[0].Color,
    ItineraryStatus: arrayIntinerary[0].ItineraryStatus,
    ItineraryObservation: arrayIntinerary[0].ItineraryObservation,
  };

  this.loadingOverlay = true;
  await this.$http
    .put('ServiceItinerary-update', ServiceItineraryJson, {
      root: 'ServiceItineraryJson',
    })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });

  await this.mountedMont();
}

async function checkServicePortList(id) {
  //this.loadingOverlay = true;
  this.OrigitPortId = '';
  this.loadingServicesPort = true;

  await this.$http
    .get(`ServicePort-list?ServiceId=${id}`)
    .then((response) => {
      response.data.data.map((item) => {
        if (item.PortId === this.branch.PortId) {
          this.OrigitPortId = item.ServicePortId;
        }
      });
      if (this.OrigitPortId === '') {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: 'El puerto destino no corresponde al puerto de la sucursal',
          type: 'error',
        });
      }
    })
    .finally(() => {
      this.loadingServicesPort = false;
    })
    .catch((e) => {
      this.loadingServicesPort = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function eventSelectedCalendar(event) {
  const arrayIntinerary = this.events.filter(
    (element) => element.ItineraryId === event.id
  );
  this.showModal = true;
  this.editModal = true;
  this.titleModal = '';//this.$t('label.edit')+": "+arrayIntinerary[0].ItineraryObservation;
  this.itinerarySelected = arrayIntinerary[0];
}

function computedYears() {
  if(this.yearsSelected.length === 0){
    return [{
      value: parseInt(this.year), 
      label: '',
    }];
  }else{
    var chart = [];
    this.yearsSelected.map(function(e){
      chart.push({
        value: e.Year, 
        label: '',
      })
    })
    return chart;
  }
}

async function mountedMont() {
  let date = new Date();
  let month = parseInt(date.getMonth());
  let Day = date.getDate();
  //this.today = `${Day} de ${this.months[month].label} ${date.getFullYear()}`;
  this.Month = month + 1;
  this.year = parseInt(date.getFullYear());
  await this.mountedYear();
  await this.mountedItinerarioList();
  await this.mountedVesselList();
  await this.mountedServiceList();
}

async function mountedYear(){
  this.loadingOverlay = true;
    this.$http
      .get('YearMax-list')
        .then((response) => (this.yearsSelected = response.data.data))
        .finally(() => {
          this.loadingOverlay = false;
        })
        .catch((e) => {
          this.loadingOverlay = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: 'error',
          });
        });
    }

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedItinerarioList() {
  this.loadingOverlay = true;
  this.config = await localStorage.getItem('user');
  this.config = JSON.parse(this.config);
  if (typeof(this.ServiceId)=="undefined") this.ServiceId = "";
  if (Object.keys(this.config).length !== 0) {
    this.CompanyBranchId = this.config.BranchJson[0].CompanyBranchId;
  }
  this.loadingTable = true;

  this.$http
    .get(
      `ServiceItinerary-list-by-year-month?CompanyBranchId=${this.CompanyBranchId}&VesselId=${this.VesselId}&ServiceId=${this.ServiceId}&Year=${this.year}&Month=${this.Month}`
    )
    .then((response) => {
      this.events = response.data.data;
      let mes = this.Month;
      if (mes < 10) mes = '0'+this.Month;
      this.$refs.calendar.fireMethod('gotoDate', this.year+'-'+mes+'-01');
    })
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

async function selectMonth(event) {
  this.Month = event.target.value;
  this.filterActive = true;
  await this.mountedItinerarioList();
}

async function selectYear(event) {
  this.year = event.target.value;
  this.filterActive = true;
  await this.mountedItinerarioList();
}

//### Funcion mounted para cargar los datos
async function mountedVesselList() {
  this.loadingTable = true;
  let ruta = 'Vessel-list';

  if ((this.ServiceId!="") && (this.ServiceId!=undefined)) ruta = "ServiceVessel-list?ServiceId="+this.ServiceId;

  this.$http
    .get(ruta)
    .then((response) => (this.itemsBuque = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}
//### Funcion computed para formatear los datos de la tabla ###
function computedVesselList() {
  if (this.itemsBuque.length > 0) {
    return this.itemsBuque.map((item) => {
      return {
        label: item.VesselName,
        value: item.VesselId,
        icon:
          ((item.Icon !== '') && (item.Icon !== undefined) && (item.Icon !== null))
            ? `${this.$store.getters["connection/getBase"]}${item.Icon}`
            : `${this.$store.getters["connection/getBase"]}${item.VesselFlagRoute}`,
      };
    });
  }
}

async function selectVessel(payload) {
  if (payload !== null) {
    this.VesselModel = payload.label;
    this.VesselId = payload.value;
    this.filterActive = true;
    await this.mountedItinerarioList();
  } else {
    this.VesselModel = '';
    this.VesselId = '';
  }
}

function computedEvents() {
  if (this.events.length > 0) {
    return this.events.map((item) => {
      let timezone_offset_minutes = new Date(item.Etd).getTimezoneOffset();
      timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
      let horas = timezone_offset_minutes/60;
      let nuevo = new Date(item.Etd);
      if (horas < 0) nuevo.setHours(nuevo.getHours() + (horas*-1));
      if (nuevo.getHours() < 12) nuevo.setHours(nuevo.getHours() + 12);

      return {
        id: item.ItineraryId,
        title: `${item.VesselName}, ${item.Voyage}, ETA ${DateFormater.formatDateTimeWithSlash(item.Eta)} - ETD ${DateFormater.formatDateTimeWithSlash(item.Etd)} `,
        start: item.Eta,
        end: nuevo,
        backgroundColor: item.Color,
        textColor: '#F0F0F0',
        editable: false,
        borderColor: item.Color,
      };
    });
  }
}

function rondedHour(value) {
  if (parseInt(value) < 10) {
    return `0${value}`;
  } else {
    return value;
  }
}

function getHourDate(date) {
  if (date && date !== '') {
    let newDate = new Date(date);

    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();

    return `${this.rondedHour(hour)}:${this.rondedHour(minutes)}`;
  } else {
    return '';
  }
}

//### Funcion mounted para cargar los datos
async function mountedServiceList() {
  //this.loadingOverlay = true;
  this.loadingTable = true;

  this.$http
    .get('Service-list?CompanyBranchId='+this.branch.CompanyBranchId)
    .then((response) => (this.itemsServices = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}
//### Funcion computed para formatear los datos de la tabla ###
function computedServiceList() {
  if (this.itemsServices.length > 0) {
    return this.itemsServices.map((item) => {
      return {
        label: item.ServiceCode,
        value: item.ServiceId,
      };
    });
  }
}

async function selectService(payload) {
  if (payload !== null) {
    this.ServcieModel = payload.label;
    this.ServcieId = payload.value;
    this.filterActive = true;
    await this.mountedItinerarioList();
    this.$store.commit('servicio/set', { value: payload.value, label: payload.label });
  } else {
    this.ServcieModel = '';
    this.ServcieId = '';
    this.$store.commit('servicio/set', '');
  }
}

function months(){
  return [
    { label: this.$t('label.january'), value: 1 },
    { label: this.$t('label.february'), value: 2 },
    { label: this.$t('label.march'), value: 3 },
    { label: this.$t('label.april'), value: 4 },
    { label: this.$t('label.may'), value: 5 },
    { label: this.$t('label.june'), value: 6 },
    { label: this.$t('label.july'), value: 7 },
    { label: this.$t('label.august'), value: 8 },
    { label: this.$t('label.september'), value: 9 },
    { label: this.$t('label.octuber'), value: 10 },
    { label: this.$t('label.november'), value: 11 },
    { label: this.$t('label.december'), value: 12 },
  ];
}

function today(){
  let date = new Date();
  let month = parseInt(date.getMonth());
  let Day = date.getDate();
  return `${this.months[month].label} ${Day}, ${date.getFullYear()}`;
}

function configCallendar() {
  let sendCAllendarConfig = {
      locale: this.$i18n.locale,
      defaultView: 'month',
      //events: this.events,
      editable:false,
      validRange: rangoFecha(this.yearsSelected)
  };
  return sendCAllendarConfig;
}

function rangoFecha(yearsSelected) {
  let beginningYear = yearsSelected.length!=0?yearsSelected[0].Year:2022;
  let EndYear = yearsSelected.length!=0?yearsSelected[yearsSelected.length-1].Year:2022;
  var anioAtras = new Date(beginningYear,0,1);
  var anioSiguiente = new Date(EndYear,11,31);
  
  return {
    start: anioAtras,
    end: anioSiguiente
  };
}

export default {
  name: 'indexItinerario',
  data,
  components: {
    FullCalendar,
    modalItinerario,
    ModalHelpItinerary,
  },
  methods: {
    mountedYear,
    mountedMont,
    toggleShowModalCrearItinerario,
    updateCLoseModal,
    getHourDate,
    rondedHour,
    mountedVesselList,
    selectVessel,
    mountedItinerarioList,
    mountedServiceList,
    selectService,
    selectMonth,
    selectYear,
    refresCalendar,
    eventDrop,
    eventSelectedCalendar,
    checkServicePortList,
    rangoFecha
  },
  mixins: [ChoferVehiculo],
  mounted: mountedMont,
  watch: {
    ServicioId: function(newService) {
      
      if(newService){
        this.ServiceId = newService.value;
        this.ServiceModel = {
          value: newService.value,
          label: newService.label
        }
        this.mountedItinerarioList();
        this.mountedVesselList();
      } else {
        this.ServiceModel = "";
      }
    },
    ServiceModel: function(nuevo) {
      if (nuevo != null) {
        this.ServiceId = nuevo.value;
        this.mountedVesselList();
      } else {
        this.ServiceId = "";
      }        
      this.mountedItinerarioList();
    },
    actList: function(nuevo) {
      this.mountedServiceList();
    },
    configCallendar: function(nuevo) {  
      this.callendarKey+= 1;
      this.mountedItinerarioList();
    },
  },
  computed: {
    computedYears,
    computedEvents,
    computedVesselList,
    months,
    today,
    computedServiceList,
    configCallendar,
    ...mapState({
      branch: (state) => state.auth.branch,
      //ServicioId: state => state.servicio.servicioId,
      actList: state => state.servicio.actList,
      user: state => state.auth.user,
    }),
  },
  //mixins: [ChoferVehiculo],
};
</script>
<style scoped>
.position-icon{
  cursor: pointer;
  position: absolute;
  top: 1%;
  left: 95%;
  z-index: 1;
}
</style>
<style lang="scss">
.halto-servicio {
  overflow-y: auto;
}
.select-services--vesselIndex .vs__dropdown-menu {
  max-height: 280px !important;
}
.calendar-itinerary {
  .fc-content,
  .fc-list-item {
    cursor: pointer !important;
  }
  table {
    tbody {
      table {
        tbody {
          tr {
            .fc-today {
              background: lightgrey !important;
            }
          }
        }
      }
    }
  }
}
</style>
